<template>
  <div class="index"></div>
</template>

<script>
import { service } from "@/assets/js/request";
import { APPconfig } from "../../static/js/config";
export default {
  name: "index",
  data() {
    return {
      unionid: "o4ZXE6F3FGEtHY7S8VquihSoJMpg", //获取微信的id
      //接口返回的数据
      userInfo: {
        userId: "",
        idCard: "",
        address: "", //天气
        deptId: "", //天气、报错
        phone: "",
      },
      //    userInfo: {
      //     userId: "d0ce1158a13211e9a21000163e03bdbf",
      //     idCard: "652322198012194011",
      //     address: "102团一连", //天气
      //     deptId: "171", //天气、报错
      //     phone: "15733211179",
      //   },
    };
  },
  mounted() {
    //获取微信unionid
    var query = window.location.search.substr(1);
    console.log(query);
    if (query === "" || query === "undifined") {
      console.log("未获取到参数");
      return false;
    }
    if (query.indexOf("&") != -1) {
      var params = query.split("&");
      for (var i = 0; i < params.length; i++) {
        var kv = params[i].split("=");
        if (kv[0] == "unionid") {
          this.unionid = kv[1];
        } else if (kv[0] == "phone") {
          this.userInfo.phone = kv[1];
        }
      }
    } else {
      var kv = query.split("=");
      this.unionid = kv[1];
    }
    //this.unionid = "652301194809105530";
    localStorage.setItem("unionid", this.unionid);
    localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
    //获取用户信息
    this.getUserInfo();
  },

  methods: {
    getUserInfo() {
      //调用校验接口
      service
        .get(APPconfig.apiUrl.idCardLogin, { params: { idCard: this.unionid } })
        .then((res) => {
          if (res.success) {
            //将用户信息存入缓存
            this.userInfo = res.obj;
            localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
            //进入信息绑定页面
            console.log("校验成功，进入信息绑定");
            this.$router.push({
              path: "/binding",
            });
          } else {
            //用户未注册|用户手机号失效，请重新登录
            console.log("进入空地图页面");
            //进入登录页面
            this.$router.push({
              path: "/emptyMap",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
