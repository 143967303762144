import Vue from 'vue';
import VueRouter from 'vue-router';
import index from '../views/index'
Vue.use(VueRouter);

const routes = [{
        // 校验页面
        path: '/',
        redirect: '/index',
        component: index,
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
    { // 主页
        path: '/index',
        name: 'index',
        component: index,
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
    { // 主页
        path: '/main',
        name: 'main',
        component: () =>
            import ('../views/main'),
        meta: {
            keepAlive: true, // 此组件需要被缓存
            isBack: false // 用于判断上一页是哪个
        }
    },
    {
        // 空地图
        path: '/emptyMap',
        name: 'emptyMap',
        component: () =>
            import ('../views/emptyMap'),
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },{
        // 更多信息
        path: '/moreInfo',
        name: 'moreInfo',
        component: () =>
            import ('../views/moreInfo'),
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
    {
        // 地块绑定
        path: '/binding',
        name: 'binding',
        component: () =>
            import ('../views/binding'),
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
    {
        // 地块绑定修改
        path: '/bindingUpdate',
        name: 'bindingUpdate',
        component: () =>
            import ('../views/bindingUpdate'),
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
    {
        // 天气预报
        path: '/weather',
        name: 'weather',
        component: () =>
            import ('../views/weather'),
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
    {
        // 个人信息
        path: '/userInfo',
        name: 'userInfo',
        component: () =>
            import ('../views/userInfo'),
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
    {
        // 报错提交
        path: '/error',
        name: 'error',
        component: () =>
            import ('../views/error'),
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
    {
        // 报错提交列表
        path: '/errorList',
        name: 'errorList',
        component: () =>
            import ('../views/errorList'),
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
    {
        // 报错详情
        path: '/errorDetail',
        name: 'errorDetail',
        component: () =>
            import ('../views/errorDetail'),
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
    {
        // 登录
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/login'),
        meta: {
            keepAlive: false, // 此组件不需要被缓存
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;