import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/base.css'
import ElementUI from 'element-ui';
import '../static/elementUI/theme/index.css';
import axios from 'axios'
Vue.prototype.axios = axios;
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');