// 配置文件
const configJson = {
    //地图
    Map: {
        //token=a8479c5bd1fd7b84c5a72669ed79a95b
        TianDiTu: {
            Normal: {
                Map: "https://t{s}.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=e7ff8e5539540a739d4332323c7d3e75",
                Annotion: "https://t{s}.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=e7ff8e5539540a739d4332323c7d3e75"
            },
            Satellite: {
                Map: "https://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=e7ff8e5539540a739d4332323c7d3e75",
                Annotion: "https://t{s}.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=e7ff8e5539540a739d4332323c7d3e75"
            },
            Terrain: {
                Map: "https://t{s}.tianditu.gov.cn/ter_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ter&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=e7ff8e5539540a739d4332323c7d3e75",
                Annotion: "https://t{s}.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=e7ff8e5539540a739d4332323c7d3e75"
            },
            Subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
        },
        arcgis: {
            url: "https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
        },
    },
    // 地图层级控制
    // 团的缩放层级
    regimentZooms: [7, 10],
    // 连的缩放层级
    companyZooms: [11, 13],
    // 地块缩放层级
    landZooms: [14, 15],

    // 师、团、连、地块、服务
    mapLayerUrl: {
        regiment: { url: '', selectKey: 'Alias', zoom: 9, num: '' },
        company: { url: '', selectKey: 'Alias', zoom: 12, num: '' },
        land: { url: '', selectKey: 'JYRSFZH', zoom: 15, num: '' },
        all: { url: '' },
    },
    // api 接口路径
    apiUrl: {
        //登录===
        //微信校验，参数unionid,返回用户相关信息
        checkin: "/applets/user/checkin",
      idCardLogin: "/applets/user/idCardLogin",
        //短信发送验证码，参数mobile
        code: '/sms/code/login',
        //登录，参数unionid、mobile、code，返回用户相关信息
        login: '/applets/sms/login',

        //地块查询，参数userId=d0ce1158a13211e9a21000163e03bdbf
        getLand: "/app/fieldList",
        // 地块绑定
        bindLand: "/applets/user/bindField",

        // ========遥感监测分析========
        //地图矢量服务
        mapServer: "/map/vector/server",
        //图例，参数type，监测类型
        legendList: "/plate/legendList",
        // 服务列表 参数 bizType 菜单类型 userId
        serverList: "/app/serverList",
        // 天气预报 orgCode: 下级code
        weather: "/weather/forecast",
        //预警列表
        warning: "/weather/warning",


        // 统计分析
        // 1 作物分布 参数deptId：单位id orgCode: 下级code  serverId: 服务id
        cropCount: "/app/cropCount",
        // 2 播种进度 参数deptId: 单位id orgCode: 下级code type: 作物品类  serverId: 服务id
        sowingCount: "/app/sowingCount",
        // 3 出苗率 参数deptId: 单位id orgCode: 下级code type: 作物品类  serverId: 服务id
        sproutCount: "/app/sproutCount",
        // 4 长势检测 参数deptId: 单位id orgCode: 下级code type: 作物品类 serverId: 服务id
        growCount: "/app/growCount",
        // 5 旱情监测 参数deptId: 单位id orgCode: 下级code type 作物品类   serverId: 服务id
        droughtCount: "/app/droughtCount",
        // 6 棉花吐絮率 参数deptId: 单位id orgCode: 下级code  serverId: 服务id
        bollCount: "/app/bollCount",
        // 7 棉花脱叶 参数deptId: 单位id orgCode: 下级code  serverId: 服务id
        disleaveCount: "/app/disleaveCount",
        // 8 产量评估 参数deptId: 单位id orgCode: 下级code  type：作物品类  serverId: 服务id
        harvestCount: "/app/harvestCount",
        // 9 采摘进度 参数deptId: 单位id orgCode: 下级code type：作物品类  serverId: 服务id
        recoveryCount: "/app/recoveryCount",
        // 10 灾害检测 参数deptId: 单位id orgCode: 下级code type：灾害类型 风灾、涝灾、病虫害 serverId: 服务id
        disasterCount: "/app/disasterCount",

        // 对比分析
        // 1 作物分布 参数deptId：单位id orgCode: 下级code  serverId1: 服务id serverId2 : 服务id2
        cropCompare: "/app/cropCompare",
        // 2 播种进度 参数deptId: 单位id orgCode: 下级code type: 作物品类 serverId1: 服务id serverId2 : 服务id2
        sowingCompare: "/app/sowingCompare",
        // 3 出苗率 参数deptId: 单位id orgCode: 下级code type: 作物品类 serverId1: 服务id serverId2 : 服务id2
        sproutCompare: "/app/sproutCompare",
        // 4 长势检测 参数deptId: 单位id orgCode: 下级code type: 作物品类 serverId1: 服务id serverId2 : 服务id2
        growCompare: "/app/growCompare",
        // 5 旱情监测 参数deptId: 单位id orgCode: 下级code type 作物品类  serverId1: 服务id serverId2 : 服务id2
        droughtCompare: "/app/droughtCompare",
        // 6 棉花吐絮率 参数deptId: 单位id orgCode: 下级code  serverId1: 服务id serverId2 : 服务id2
        bollCompare: "/app/bollCompare",
        // 7 棉花脱叶 参数deptId: 单位id orgCode: 下级code  serverId1: 服务id serverId2 : 服务id2
        disleaveCompare: "/app/disleaveCompare",
        // 8 产量评估 参数deptId: 单位id orgCode: 下级code  type：作物品类 serverId1: 服务id serverId2 : 服务id2
        harvestCompare: "/app/harvestCompare",
        // 9 采摘进度 参数deptId: 单位id orgCode: 下级code type：作物品类  serverId1: 服务id serverId2 : 服务id2
        recoveryCompare: "/app/recoveryCompare",
        // 10 灾害检测 参数deptId: 单位id orgCode: 下级code type：灾害类型 风灾、涝灾、病虫害 serverId1: 服务id serverId2 : 服务id2
        disasterCompare: "/app/disasterCompare",

        //审批流程
        //审批意见提交-农户提交
        // {
        //     "type":"信息绑定",
        //     "submitter":"44",
        //     "submitterType":"农户",
        //     "deptId":"177",
        //     "title":"信息无法绑定",
        //     "content":"身份证号没有地块返回，应该有3块地"
        //     }
        //提交报错
        errorAdd: "/dimErrorProblem/add",
        //详情，参数approvalId
        errorDetail: "/dimErrorProblem/getById",
        //获取历史列表,参数phone
        errorList: "/dimErrorProblem/getErrorList",

        // 种植方案 参数 bizType 菜单类型
        planList: "app/planList",
        // 灾害列表
        disasterList: "/app/disasterList"
    },
    // 遥感监测分析 菜单数值等级对应类型
    menuLevel: {
        // 作物分布
        crop: { value: '面积', type: '农作物' },
        // 播种进度
        sowing: { level1: '播种', level2: '未播种' },
        // 出苗率
        sprout: { level1: '<75%', level2: '75%-85%', level3: '85%-95%', level4: '≥95%' },
        // 长势检测
        grow: { level1: '差', level2: '中', level3: '好' },
        grow1: { level1: '差', level2: '较差', level3: '中', level4: '较好', level5: '好' },
        // 旱情检测
        drought: {
            level1: '特旱',
            level2: '中旱',
            level3: '轻旱',
            level4: '正常'
        },
        //棉花吐絮
        boll: {
            level1: "＜75%",
            level2: "75%-85%",
            level3: "85%-95%",
            level4: "≥95%"
        },
        // 棉花脱叶
        disleave: {
            level1: "＜75%",
            level2: "75%-85%",
            level3: "85%-95%",
            level4: "≥95%"
        },
        // 产量评估
        harvest: {
            level1: "低产",
            level3: "中产",
            level2: "高产",
            // total: "总计"
        },
        // 采收经度
        recovery: {
            level1: "采收",
            level2: "未采收",
            // total: '总计'
        },
        // 灾害检测
        disaster: {
            level1: "轻灾",
            level2: "中灾",
            level3: "重灾",
            // total: '总计'
        },
        // 灾害检测
        disaster1: {
            level1: "绝产",
            level2: "重灾",
            level3: "中灾",
            level4: "轻灾",
            level5: "正常",
        }

    },
    land: {
        旱情监测: {
            特旱: " linear-gradient(90deg,#f50000 0%,#f50000 32%,#000 33%,#fa4f00 34%,#fa4f00 66%,#000 67%,#fc8200 68%,#fc8200 100%)",
            中旱: "linear-gradient(90deg,#fcb100 0%,#fcb100 24%,#000 25%,#f7df00 26%,#f7df00 50%,#000 51%,#e0f500 52%,#e0f500 76%,#000 77%,#baf700 78%,#baf700 100%)",
            轻旱: "linear-gradient(90deg,#8cf700 0%,#8cf700 32%,#000 33%,#5bf700 34%,#5bf700 66%,#000 67%,#00f500 68%,#00f500 100%)"
        },
        长势监测: {
            长势差: "linear-gradient(90deg, #f50000 0 %, #f50000 32 %,#000 33 %, #fa4f00 34 %, #fa4f00 66 %,#000 67 %, #fc8200 68 %, #fc8200 100 %)",

            长势一般: "linear - gradient(90deg, #fcb100 0 %, #fcb100 24 %,#000 25 %, #f7df00 26 %, #f7df00 50 %,#000 51 %, #e0f500 52 %, #e0f500 76 %,#000 77 %, #baf700 78 %, #baf700 100 %)",

            长势好: "linear - gradient(90deg,#8cf700 0 %,#8cf700 32 %,#000 33 %,#5bf700 34 %,#5bf700 66 %,#000 67 %,#00f500 68 %,#00f500 100 %)"
        }
    }

}
export const APPconfig = configJson
